import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom'; 
import Home from './pages/Home.js';
import './App.css';
import { Provider as BumbagProvider } from 'bumbag';
import { ScrollTrigger, Timeline, Tween } from 'react-gsap';
import { gsap } from 'gsap';

const theme = {
  global: {
    fontSize: 18,
    styles: {
      base: `
        html,
        body {
          background-color: #0D0D0D;
          color: #FFFBFC;
        }
      `
    }
  },
  palette: {
    primary: '#9c45ff',
    primaryTint: 'black'
  },
  breakpoints: {
    mobile: 520,
    tablet: 960
  },
  Button: {
    defaultProps: {
      palette: 'primary'
    }
  },
  Heading: {
    styles: {
      base: {
        color: 'primary'
      }
    }
  }
}

class App extends React.Component {  
  render() {
    return (
      <BumbagProvider theme={theme}>
        <BrowserRouter>
          <div className="App">
            <Route exact path="/" component={Home} />
          </div>
        </BrowserRouter>
      </BumbagProvider>
    );
  };
}

export default App;
