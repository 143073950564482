import React, {useEffect, useState} from 'react';
import Lottie from 'react-lottie';
import '../App.css';
import { Container, Box, Divider, Stack, Flex } from 'bumbag';
import scrollDown from '../assets/scrolldown.json';
import nowPlaying from '../assets/nowplaying.json';
import { useLastFM } from "use-last-fm";
import { IconContext } from "react-icons";
import { FaBehance, FaLinkedin, FaGithub } from 'react-icons/fa';

function Home() {
    
    const lastFM = useLastFM("aero-panda_", "9b155fbd60a87b482dda4be6ff1c211a"); // lol that username...
    // const projectsRef = React.useRef()
    // var codeRef = null;
    // const [alternateBg, setAlternateBg] = useState(true);


    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: scrollDown,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    useEffect(() => {
        // gsap.timeline({
        //     scrollTrigger: {
        //       scrub: 1,
        //       trigger: '#projects',
        //       pin: '#projects',
        //       start: 'top 5%',
        //       end: 'bottom top',
        //       markers: false
        //     }
        // })
        // .fromTo(".project-left", {x: 0}, {x: -500, duration: 1, delay: 0.5})
        // .fromTo(".project-right", {opacity: 0.2}, {opacity: 1})
        // .fromTo(".project-left", {}, { delay: 5});
        
        // gsap.timeline({
        //     scrollTrigger: {
        //       scrub: 1,
        //       trigger: '#mac',
        //       pin: true,
        //       start: 'center center',
        //       end: 'bottom top',
        //       markers: true
        //     }
        // })
        // .fromTo("#mac-video", {width: '100%'}, {width: '125%', duration: 1, delay: 0.5});

    })


    return (
        <>
            <Container className='container' isFluid style={{backgroundColor: '#0d0224', transition: "background-color 2s ease-in-out"}}>
            {/* <Container isFluid> */}
                <Box className="content">
                        <Box alignY="center" className="hero">
                                {/* <Tween
                                    from={{ scale: 1, opacity: 1  }}
                                    to={{ scale: 1.1, opacity: 0.1 }}
                                    stagger={{ from: 'center', amount: 2, grid: [10, 10] }}
                                    duration={2}
                                > */}
                                    <h1 className="hero-title header-effect">Adam Greenwood</h1>
                                    <h2 className="hero-subtitle text-uppercase">Lead Designer @ Cornerstone Marketing Solutions</h2>

                                    <IconContext.Provider value={{ size: '5vw' }}>
                                        <Stack orientation="horizontal" className='social-stack' style={{marginTop: '10px'}}>
                                            <a href='https://www.behance.net/adamgre' target='_blank'>
                                                <FaBehance style={{maxWidth: '30px'}} />
                                            </a>
                                            <a href='https://www.linkedin.com/in/adam-gre/' target='_blank'>
                                                <FaLinkedin style={{maxWidth: '30px'}} />
                                            </a>
                                            <a href='https://github.com/adam-gre' target='_blank'>
                                                <FaGithub style={{maxWidth: '30px'}} />
                                            </a>
                                        </Stack>
                                    </IconContext.Provider>
                                    <Divider borderColor="#9c45ff" className="hero-divider" />  
                                {/* </Tween> */}
                                { lastFM.status === "playing" ?
                                    <div className="spotify">
                                        
                                        <Flex alignY="center" style={{marginTop: '10px'}}>
                                            <Lottie 
                                                options={{
                                                    loop: true,
                                                    autoplay: true, 
                                                    animationData: nowPlaying,
                                                    rendererSettings: {
                                                        preserveAspectRatio: 'xMidYMid slice'
                                                    }
                                                }}
                                                height={25}
                                                width={25}
                                            />
                                            <p className='nowplaying'>Listening to {<a href={lastFM.song.url}>{lastFM.song.name}</a>} by {lastFM.song.artist} </p>
                                        </Flex>
                                    
                                    </div>
                                    :
                                    <p style={{ paddingLeft: "10px", display: "inline-block", fontSize: "15px", lineHeight: "18px", marginBottom: 0, opacity: 0, verticalAlign: 'middle'}}>Not listening to anything ¯\_(ツ)_/¯</p>
                                    
                                 }        
                                {/* <h2 className="hero-scrolltext">Scroll down
                                    <Lottie style={{ display: "inline-block", verticalAlign: "middle"}} 
                                    options={defaultOptions}
                                        height={50}
                                        width={50}/>
                                </h2> */}
                        </Box>
                    </Box>    
                    {/*   
                    <Box alignX="left" textAlign="left" className="section" id="mac"> 
                        <Box className="project">
                            <Flex width="100%">
                                <Box id="mac-content">                           
                                    <video src={macVideo} width='100%' height='auto' id='mac-video'></video>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>             
                    <Box alignX="left" textAlign="left" height="200vh" className="section" id="projects"> 
                        <Box className="project">
                            <Flex width="100%">
                                <Box className="project-left">                           
                                    <img src={link} width="100%" alt="The Link Stockton" id="link-image" className="project-image" />                                    
                                    <h1 className="text-uppercase project-title" id="link-title">The Link</h1>
                                </Box>
                                <Box className="project-right">
                                    <h4>The Link Stockton</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu dolor enim. Aenean eu pulvinar eros. Pellentesque pulvinar varius sapien, non finibus ligula feugiat eu. Fusce vulputate dui lectus. Nam auctor commodo magna, sit amet molestie odio rhoncus quis. Nunc id leo malesuada ex dignissim rutrum. Nam dictum sapien diam, a sollicitudin neque congue id. Aenean hendrerit magna nec magna pretium, in vulputate nulla viverra. Ut congue justo sem. Vestibulum lacinia nisl vel magna tristique, sed vestibulum tellus pretium. Aliquam congue nibh nisl, quis sagittis quam sodales in.
<br />
Nullam dignissim blandit risus a condimentum. Curabitur congue sit amet mi non mattis. Ut maximus lectus molestie sem tincidunt, et consectetur lacus vulputate. Donec at ex lorem. Pellentesque lorem ex, efficitur ac enim quis, pharetra auctor nulla. Cras ut dolor vel erat pretium feugiat. Sed vel quam lacus. Ut ipsum metus, finibus id mollis vel, faucibus non mauris. Nam auctor erat ullamcorper posuere varius. In est orci, volutpat sed commodo eu, pulvinar a massa. Curabitur sed condimentum elit. Vivamus sed condimentum lorem. Mauris tempor lacus at justo feugiat consectetur. Nullam hendrerit vehicula nibh a vulputate. Etiam pretium justo sit amet lorem auctor eleifend.</p>
                                </Box>
                            </Flex>
                        </Box>
                    <Box alignX="left" textAlign="left" height="100vh" className="section">

                        <ScrollTrigger start="-500vh center" end="-250vh center" scrub={1}
                            onEnter={() => {
                                setAlternateBg(true)
                            }}
                            onEnterBack={() => {
                                setAlternateBg(true)
                            }}
                        >
                            <Tween
                                from={{
                                    x: '-700vw'
                                }}
                                to={{
                                    x: '0'
                                }}
                            >                        
                                <h1 className="hero-title">MY SKILLSET</h1>
                            </Tween>                            
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                    x: '0'
                                }}
                            >                         
                                <Divider borderColor="#9c45ff" width="25%" />
                            </Tween>                         
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                    x: '0'
                                }}
                            >                         
                                <h5 style={{marginBottom: "0"}}>LANGUAGES</h5>
                            </Tween>                                                    
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                x: '0',
                                }}
                            >
                                
                                <h3>HTML & CSS</h3>
                                <ProgressBar value={100} />
                            </Tween>
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                x: '0',
                                }}
                            >
                                
                                <h3>JavaScript</h3>
                                <ProgressBar value={100} />
                            </Tween>
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                    x: '0'
                                }}
                            >                         
                                <h5 style={{marginBottom: "0", marginTop: "5%"}}>FRAMEWORKS</h5>
                            </Tween> 
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                x: '0',
                                }}
                            >
                                
                                <h3>React</h3>
                                <ProgressBar value={75} />
                            </Tween>
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                    x: '0'
                                }}
                            >                         
                                <h5 style={{marginBottom: "0", marginTop: "5%"}}>TECHNOLOGY</h5>
                            </Tween> 
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                x: '0',
                                }}
                            >
                                
                                <h3>Wordpress</h3>
                                <ProgressBar value={75} />
                            </Tween>
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                x: '0',
                                }}
                            >
                                
                                <h3>Elementor</h3>
                                <ProgressBar value={100} />
                            </Tween>
                        </ScrollTrigger>
                    </Box>                    
                    <Box alignX="left" textAlign="left" height="75vh" className="section">
                        <ScrollTrigger start="-500vh center" end="-250vh center" scrub={1}
                            onEnter={() => {
                                setAlternateBg(false)
                            }}
                            onEnterBack={() => {
                                setAlternateBg(false)
                            }}
                            // pin={true}
                            // trigger={projectsRef.current}
                        >
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                    x: '0'
                                }}
                            >                        
                                <h1 className="hero-title" id="past_projects" ref={projectsRef}>PAST PROJECTS</h1>
                            </Tween>                  
                            <Tween
                                from={{
                                    x: '-750vw'
                                }}
                                to={{
                                    x: '0'
                                }}
                            >                         
                                <p style={{marginBottom: "0"}}>Nothing here yet ¯\_(ツ)_/¯</p>
                            </Tween>   
                        </ScrollTrigger>
                        </Box>

                </Box> */}
            </Container>
        </>
    );
}

export default Home;
